import { Api } from "@api/Api";
import { AnyAssetFolderBase } from "@api/ApiTypes";
import { Asset } from "@api/graphql/types";
import { LoctoolDate, LoctoolMessage, useLoctool, withLoctool, WithLoctoolProps } from "@bigfish/react-loctool";
import { Button } from "@components/Button";
import { Element } from "@components/Element";
import { Flex } from "@components/Flex";
import { Input } from "@components/Input";
import { ControlledMenu } from "@components/Menu";
import Modal from "@components/Modal";
import { Separator } from "@components/Separator";
import { SSkeletonLine } from "@components/Skeleton";
import SvgIconArrowChevronBack from "@components/svg/IconArrowChevronBack";
import SvgIconArrowChevronForward from "@components/svg/IconArrowChevronForward";
import SvgIconCheck from "@components/svg/IconCheck";
import SvgIconDownload from "@components/svg/IconDownload";
import SvgIconEdit from "@components/svg/IconEdit";
import SvgIconFileStructure from "@components/svg/IconFileStructure";
import SvgIconFolder from "@components/svg/IconFolder";
import SvgIconFullScreenExpandResize from "@components/svg/IconFullScreenExpandResize";
import SvgIconLink from "@components/svg/IconLink";
import { Text } from "@components/Text";
import { AlertActions } from "@redux/alertSlice";
import { useAppDispatch } from "@redux/store";
import { FocusableItem, useMenuState } from "@szhsin/react-menu";
import { bp, Shadow } from "@theme/Theme";
import { FileSizeUtils } from "@utils/FileSizeUtils";
import { download } from "@utils/Helpers";
import React, { FormEvent, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import styled from "styled-components";
import { FolderButton, FolderSelect, ParentFolderButton } from "./AssetMoveModal";

type Props = {
    mounted: boolean;
    onModalClose: () => void;
    asset: Asset;
} & WithLoctoolProps &
    ReduxProps;

type State = {
    asset: Asset;
};

class AssetPreviewModalComponent extends React.PureComponent<Props> {
    public state: State = {
        asset: this.props.asset,
    };

    public render(): React.ReactNode {
        const { mounted, onModalClose, Loctool } = this.props;
        const { asset } = this.state;

        return (
            <StyledAssetPreviewModal
                heading={Loctool.formatMessage({ id: "components.assetPreviewModal.title" })}
                hideHeading
                mounted={mounted}
                onModalClose={onModalClose}
            >
                <Text
                    as="h2"
                    variant="linkMedium"
                    $style={{ paddingRight: 20, fontWeight: 600, wordBreak: "break-all" }}
                >
                    {asset.title}
                </Text>
                <Text as="p" variant="textXSmall" $style={{ wordBreak: "break-all" }}>
                    {asset.url}
                </Text>
                <Separator $style={{ margin: "20px 0 30px" }} />
                <Element
                    $style={{ marginBottom: 30 }}
                    $styleMedium={{ display: "grid", gridTemplateColumns: "1fr 255px", gridGap: 20 }}
                    $styleLarge={{ display: "grid", gridTemplateColumns: "1fr 355px", gridGap: 20 }}
                >
                    <Element
                        as="img"
                        src={`${asset.url}?w=800`}
                        alt={asset.fileName}
                        loading="lazy"
                        $style={{ display: "block", maxWidth: "100%", maxHeight: "60vh", margin: "0 auto 30px" }}
                        $styleMedium={{ marginBottom: 0 }}
                    />
                    <div>
                        <dl style={{ fontSize: 12 }}>
                            <Flex.Container $justifyContent="space-between">
                                <Element as="dt" $style={{ paddingRight: 10 }}>
                                    <LoctoolMessage id="components.assetPreviewModal.createdAt" />
                                </Element>
                                <dd>
                                    <LoctoolDate value={asset.createdAt} />
                                </dd>
                            </Flex.Container>
                            <Separator $style={{ margin: "5px 0" }} />
                            <Flex.Container $justifyContent="space-between">
                                <Element as="dt" $style={{ paddingRight: 10 }}>
                                    <LoctoolMessage id="components.assetPreviewModal.fileSize" />
                                </Element>
                                <dd>{FileSizeUtils.humanFileSize(asset.fileSize)}</dd>
                            </Flex.Container>
                            <Separator $style={{ margin: "5px 0" }} />
                            <Flex.Container $justifyContent="space-between">
                                <Element as="dt" $style={{ paddingRight: 10 }}>
                                    <LoctoolMessage id="components.assetPreviewModal.width" />
                                </Element>
                                <dd>{asset.info.width} px</dd>
                            </Flex.Container>
                            <Separator $style={{ margin: "5px 0" }} />
                            <Flex.Container $justifyContent="space-between">
                                <Element as="dt" $style={{ paddingRight: 10 }}>
                                    <LoctoolMessage id="components.assetPreviewModal.height" />
                                </Element>
                                <dd>{asset.info.height} px</dd>
                            </Flex.Container>
                        </dl>
                    </div>
                </Element>
                <Flex.Container $flexWrap="wrap">
                    <Button.Text
                        label={Loctool.formatMessage({ id: "components.assetPreviewModal.download" })}
                        startIcon={<SvgIconDownload width={16} height={16} />}
                        onClick={() => download(`${asset.url}?download=1`)}
                        adjust
                    />
                    <Separator vertical $style={{ height: 22 }} />
                    <AssetMovePopup asset={asset} />
                    <Separator vertical $style={{ height: 22 }} />
                    <AssetRenamePopup asset={asset} onRename={asset => this.setState({ asset })} />
                    <Separator vertical $style={{ height: 22 }} />
                    <Button.Text
                        label={Loctool.formatMessage({ id: "components.assetPreviewModal.open" })}
                        startIcon={<SvgIconFullScreenExpandResize width={16} height={16} />}
                        onClick={() => window.open(asset.url, "_blank")}
                        adjust
                    />
                    <Separator vertical $style={{ height: 22 }} />
                    <Button.Text
                        label={Loctool.formatMessage({ id: "components.assetPreviewModal.copy" })}
                        startIcon={<SvgIconLink width={16} height={16} />}
                        onClick={async () => {
                            try {
                                await navigator.clipboard.writeText(asset.url);
                                this.props.alertSuccess({
                                    message: Loctool.formatMessage({ id: "components.assetPreviewModal.copySuccess" }),
                                });
                            } catch (error) {
                                this.props.alertError({
                                    message: Loctool.formatMessage({ id: "components.assetPreviewModal.copyError" }),
                                });
                            }
                        }}
                        adjust
                    />
                </Flex.Container>
            </StyledAssetPreviewModal>
        );
    }
}

const AssetRenamePopup = ({ asset, onRename }: { asset: Asset; onRename: (asset: Asset) => void }) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuProps, toggleMenu] = useMenuState({ transition: true });
    const [title, setTitle] = useState(asset.title);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const Loctool = useLoctool();
    const isClosed = menuProps.state === "closed" || typeof menuProps.state === "undefined";

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            await Api.updateAsset(asset.id, title);
            onRename({ ...asset, title: title });
            dispatch(
                AlertActions.success({
                    message: Loctool.formatMessage({ id: "components.assetRenamePopup.success" }),
                })
            );
            toggleMenu(false);
        } catch (error) {
            dispatch(AlertActions.parseError(error as Error));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button.Text
                label={Loctool.formatMessage({ id: "components.assetRenamePopup.triggerLabel" })}
                startIcon={<SvgIconEdit width={16} height={16} />}
                onClick={() => isClosed && toggleMenu(true)}
                ref={buttonRef}
                adjust
            />

            <ControlledMenu
                {...menuProps}
                anchorRef={buttonRef}
                align="center"
                menuItemFocus={{ position: "first" }}
                onClose={() => toggleMenu()}
                ref={menuRef}
                captureFocus
                transition
                sizeLarge
                arrow={true}
                arrowStyle={{ boxShadow: "-1px -1px 2px rgba(0,0,0,0.11)" }}
                boundingBoxPadding="20"
                offsetY={10}
                menuStyle={{ boxShadow: Shadow.small, border: "1px solid rgba(0,0,0,0.08)" }}
            >
                <Element as="form" $styleLarge={{ minWidth: 420 }} onSubmit={handleSubmit}>
                    <FocusableItem>
                        {({ ref }) => (
                            <Input
                                label={Loctool.formatMessage({ id: "components.assetRenamePopup.inputLabel" })}
                                $style={{ marginBottom: 24 }}
                                value={title}
                                onChange={e => setTitle(e.currentTarget.value)}
                                ref={ref}
                            />
                        )}
                    </FocusableItem>
                    <Button.Primary
                        label={Loctool.formatMessage({ id: "components.assetRenamePopup.submitLabel" })}
                        startIcon={<SvgIconCheck width={16} height={16} />}
                        loading={isLoading}
                        type="submit"
                        fullWidth
                    />
                </Element>
            </ControlledMenu>
        </>
    );
};

const AssetMovePopup = ({ asset }: { asset: Asset }) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuProps, toggleMenu] = useMenuState({ transition: true });
    const [folders, setFolders] = useState<AnyAssetFolderBase[]>([]);
    const [currentFolder, setCurrentFolder] = useState<AnyAssetFolderBase | null>(null);
    const [selectedFolder, setSelectedFolder] = useState<AnyAssetFolderBase | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useAppDispatch();
    const Loctool = useLoctool();
    const isClosed = menuProps.state === "closed" || typeof menuProps.state === "undefined";

    const moveAsset = async () => {
        try {
            setIsSaving(true);
            await Api.moveAssetsToAssetDirectory([asset.id], selectedFolder!.id);
            dispatch(
                AlertActions.success({
                    message: Loctool.formatMessage({ id: "components.assetMovePopup.success" }),
                })
            );
            toggleMenu(false);
        } catch (error) {
            dispatch(AlertActions.parseError(error as Error));
        } finally {
            setIsSaving(false);
        }
    };

    const fetchFolders = async (parentFolder: AnyAssetFolderBase | null) => {
        try {
            setIsLoading(true);
            const response = await Api.getAssetDirectoriesByParentId(parentFolder?.id ?? null);
            setFolders(response);
        } catch (error) {
            dispatch(AlertActions.parseError(error as Error));
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = (folder: AnyAssetFolderBase | null) => {
        setCurrentFolder(folder);
        fetchFolders(folder);
    };

    const renderFolders = () =>
        folders.map(folder => (
            <FolderSelect
                key={folder.id}
                selected={folder.id === selectedFolder?.id}
                onClick={() => setSelectedFolder(folder)}
            >
                <SvgIconFolder width={14} height={14} />
                <Text as="span" variant="textXSmall" $style={{ margin: "0 8px" }}>
                    {folder.name}
                </Text>
                {!!folder && !!folder.childrenCount && folder.childrenCount > 0 && (
                    <FolderButton onClick={() => navigate(folder)}>
                        <SvgIconArrowChevronForward width={15} height={15} />
                    </FolderButton>
                )}
            </FolderSelect>
        ));

    const renderPlaceHolders = (rowCount = 4) => (
        <>
            {new Array(rowCount).fill("1").map((_, i) => {
                return (
                    <FolderSelect key={`skeleton-${i}`}>
                        <SSkeletonLine width={"14px"} />
                        <Element $style={{ width: 10 }} />
                        <SSkeletonLine width={"60%"} />
                        <Element $style={{ width: 10, marginLeft: "auto" }} />
                        <SSkeletonLine width={"14px"} />
                    </FolderSelect>
                );
            })}
        </>
    );

    return (
        <>
            <Button.Text
                label={Loctool.formatMessage({ id: "components.assetMovePopup.triggerLabel" })}
                startIcon={<SvgIconFileStructure width={16} height={16} />}
                onClick={() => {
                    if (isClosed) {
                        navigate(null);
                        toggleMenu(true);
                    }
                }}
                ref={buttonRef}
                adjust
            />
            <ControlledMenu
                {...menuProps}
                anchorRef={buttonRef}
                align="center"
                menuItemFocus={{ position: "first" }}
                onClose={() => toggleMenu()}
                ref={menuRef}
                captureFocus
                transition
                sizeLarge
                arrow={true}
                arrowStyle={{ boxShadow: "-1px -1px 2px rgba(0,0,0,0.11)" }}
                boundingBoxPadding="20"
                offsetY={10}
                menuStyle={{ boxShadow: Shadow.small, border: "1px solid rgba(0,0,0,0.08)" }}
            >
                <Element $styleLarge={{ minWidth: 420 }}>
                    {!!currentFolder && (
                        <ParentFolderButton onClick={() => navigate(currentFolder?.parent ?? null)}>
                            <SvgIconArrowChevronBack width={15} height={15} />
                            <Element as={SvgIconFolder} $style={{ width: 14, height: 14, marginLeft: 5 }} />
                            <Text as="span" variant="textXSmall" $style={{ marginLeft: 8 }}>
                                <LoctoolMessage id="components.assetMovePopup.back" />
                            </Text>
                        </ParentFolderButton>
                    )}
                    {isLoading ? renderPlaceHolders() : renderFolders()}
                    <Button.Primary
                        label={
                            selectedFolder
                                ? Loctool.formatMessage(
                                      { id: "components.assetMovePopup.moveButtonLabel" },
                                      { folderName: selectedFolder.name }
                                  )
                                : Loctool.formatMessage({ id: "common.move" })
                        }
                        fullWidth
                        $style={{ marginTop: 24 }}
                        loading={isSaving}
                        onClick={() => moveAsset()}
                        disabled={!selectedFolder}
                    />
                </Element>
            </ControlledMenu>
        </>
    );
};

const StyledAssetPreviewModal = styled(Modal)`
    min-width: 100vw;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    overflow-y: auto;

    ${bp.medium} {
        min-width: auto;
        width: calc(100vw - 40px);
        max-width: 1300px;
        min-height: unset;
        max-height: unset;
        border-radius: 24px;
        overflow-y: hidden;
    }
`;

const mapDispatchToProps = {
    alertSuccess: AlertActions.success,
    alertError: AlertActions.error,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export const AssetPreviewModal = withLoctool(connector(AssetPreviewModalComponent));
