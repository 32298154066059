import gql from "graphql-tag";

class Fragments {
    public static readonly project = gql`
        fragment Project on Project {
            id
            title
            namespace
        }
    `;

    public static readonly account = gql`
        fragment Account on Account {
            id
            firstName
            lastName
            email
            projects {
                ...Project
            }
        }
        ${Fragments.project}
    `;

    public static readonly asset = gql`
        fragment Asset on Asset {
            id
            title
            fileName
            fileSize
            url
            assetType
            info {
                originalFileName
                mimeType
                encoding
                etag
                width
                height
            }
            directory {
                id
            }
            createdAt
            updatedAt
        }
    `;

    public static readonly assetDirectoryItem = gql`
        fragment AssetDirectoryItem on AssetDirectory {
            id
            name
            deletable
            createdAt
            updatedAt
            parent {
                id
                name
                deletable
            }
            childrenCount
            children {
                id
                name
                deletable
                createdAt
                updatedAt
                childrenCount
            }
        }
    `;

    public static readonly assetDirectoryItemBase = gql`
        fragment AssetDirectoryItemBase on AssetDirectory {
            id
            name
            parent {
                id
                name
            }
            childrenCount
            children {
                id
                name
                childrenCount
            }
        }
    `;

    public static readonly assetDirectory = gql`
        fragment AssetDirectory on AssetDirectory {
            id
            name
            parent {
                ...AssetDirectoryItem
            }
            childrenCount
            children {
                ...AssetDirectoryItem
            }
            deletable
            createdAt
            updatedAt
        }
        ${Fragments.assetDirectoryItem}
    `;

    public static readonly assetDirectoryBase = gql`
        fragment AssetDirectoryBase on AssetDirectory {
            id
            name
            parent {
                ...AssetDirectoryItemBase
            }
            childrenCount
        }
        ${Fragments.assetDirectoryItemBase}
    `;

    public static readonly assetExportJob = gql`
        fragment AssetExportJob on AssetExportJob {
            id
            assets {
                id
                title
            }
            status
            totalAssets
            progressPercent
            archiveURL
        }
    `;
}

export { Fragments };
