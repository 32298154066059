import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { Cookie } from "@utils/Cookie";
import { AppThunk, RootState } from "./store";
import * as GqlTypes from "@api/graphql/types";
import { Api } from "@api/Api";
import { ApiError, ApiErrorCode } from "@api/ApiError";
import { AssetExportActions } from "./assetExportSlice";
import { AlertActions } from "./alertSlice";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
export interface AuthState {
    me: GqlTypes.Account | null;
    authToken: string | null;
    refreshToken: string | null;
    projectId: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    me: null,
    authToken: Cookie.getAuthToken(),
    refreshToken: Cookie.getRefreshToken(),
    projectId: Cookie.getProjectId(),
    isLoading: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clear() {
            return { ...initialState, authToken: null, refreshToken: null, projectId: null };
        },
        setIsLoading(state, { payload: isLoading }: PayloadAction<boolean>) {
            state.isLoading = isLoading;
        },
        setAuthToken(state, { payload: authToken }: PayloadAction<string | null>) {
            state.authToken = authToken;
            Cookie.setAuthToken(authToken);
        },
        setRefreshToken(state, { payload: refreshToken }: PayloadAction<string | null>) {
            state.refreshToken = refreshToken;
            Cookie.setRefreshToken(refreshToken);
        },
        setProjectId(state, { payload: projectId }: PayloadAction<string | null>) {
            state.projectId = projectId;
            Cookie.setProjectId(projectId);
        },
        setMe(state, { payload: me }: PayloadAction<GqlTypes.Account>) {
            state.me = me;
        },
    },
});

const login = (loginDetails: GqlTypes.loginVariables): AppThunk => async dispatch => {
    dispatch(AuthActions.setIsLoading(true));

    try {
        const response = await Api.login(loginDetails);

        if (response) {
            const projectId = response.account.projects[0].id;
            batch(() => {
                dispatch(AuthActions.setAuthToken(response.authToken));
                dispatch(AuthActions.setRefreshToken(response.refreshToken));
                dispatch(AuthActions.setProjectId(projectId));
                dispatch(AuthActions.setMe(response.account));
            });
        } else {
            dispatch(AlertActions.error({ message: "error.auth.login", translate: true }));
        }
    } catch (error) {
        if (error instanceof ApiError) {
            const message = error.message as ApiErrorCode;
            if ([ApiErrorCode.VALIDATION, ApiErrorCode.UNAUTHENTICATED, ApiErrorCode.UNAUTHORIZED].includes(message)) {
                dispatch(AlertActions.error({ message: "error.auth.login", translate: true }));
            } else {
                dispatch(AlertActions.parseError(error));
            }
        }
    } finally {
        dispatch(AuthActions.setIsLoading(false));
    }
};

const loginWithSignature = (
    loginDetails: GqlTypes.loginWithSignatureVariables
): AppThunk<Promise<void>> => async dispatch => {
    dispatch(AuthActions.setIsLoading(true));

    try {
        const response = await Api.loginWithSignature(loginDetails);

        if (response) {
            batch(() => {
                dispatch(AuthActions.setAuthToken(response.authToken));
                dispatch(AuthActions.setRefreshToken(response.refreshToken));
                dispatch(AuthActions.setProjectId(loginDetails.projectId));
                dispatch(AuthActions.setMe(response.account));
            });
        } else {
            sendPMEvent(OutgoingEvent.AuthFailed);
            dispatch(AlertActions.error({ message: "error.auth.loginWithSignature", translate: true }));
        }
    } catch (error) {
        if (error instanceof ApiError) {
            const message = error.message as ApiErrorCode;
            if ([ApiErrorCode.VALIDATION, ApiErrorCode.UNAUTHENTICATED, ApiErrorCode.UNAUTHORIZED].includes(message)) {
                sendPMEvent(OutgoingEvent.AuthFailed);
                dispatch(AlertActions.error({ message: "error.auth.loginWithSignature", translate: true }));
            } else {
                dispatch(AlertActions.parseError(error));
            }
        }
    } finally {
        dispatch(AuthActions.setIsLoading(false));
    }
};

const logout = (): AppThunk => async dispatch => {
    try {
        await Api.logout();
        Cookie.clearAuthToken();
        Cookie.clearRefreshToken();
        Cookie.clearProjectId();
        dispatch(AuthActions.clear());
    } catch (error) {
        dispatch(AlertActions.error({ message: (error as ApiError).message }));
    }
};

const destroy = (): AppThunk => dispatch => {
    Cookie.clearAuthToken();
    Cookie.clearRefreshToken();
    Cookie.clearProjectId();
    dispatch(AuthActions.clear());
};

const fetchAccount = (): AppThunk<Promise<void>> => async dispatch => {
    try {
        dispatch(AuthActions.setIsLoading(true));
        const { me, getActiveAssetExportJobs: exportJobs } = await Api.account();
        batch(() => {
            dispatch(AuthActions.setMe(me));
            if (exportJobs.length > 0) {
                dispatch(AssetExportActions.setJobs(exportJobs));
                dispatch(AssetExportActions.startPolling());
            }
        });
    } catch (error) {
        dispatch(AlertActions.parseError(error as Error));
    } finally {
        dispatch(AuthActions.setIsLoading(false));
    }
};

export const AuthActions = { ...authSlice.actions, login, loginWithSignature, logout, destroy, fetchAccount };

export const isLoggedIn = (state: RootState) => {
    return !!state.auth.authToken;
};

export const AuthReducer = authSlice.reducer;
