import gql from "graphql-tag";
import { Fragments } from "@api/graphql/queries/Fragments";

class Queries {
    public static readonly account = gql`
        query account {
            me {
                ...Account
            }
            getActiveAssetExportJobs {
                ...AssetExportJob
            }
        }
        ${Fragments.account}
        ${Fragments.assetExportJob}
    `;

    public static readonly getAssetDirectoriesByParentIdBase = gql`
        query getAssetDirectoriesByParentIdBase($id: ID) {
            getAssetDirectoriesByParentId(id: $id) {
                ...AssetDirectoryBase
            }
        }
        ${Fragments.assetDirectoryBase}
    `;

    public static readonly getAssetDirectoriesByParentId = gql`
        query getAssetDirectoriesByParentId($id: ID) {
            getAssetDirectoriesByParentId(id: $id) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly getAssetDirectoryById = gql`
        query getAssetDirectoryById($id: ID!) {
            getAssetDirectoryById(id: $id) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly search = gql`
        query search($options: SearchInput) {
            search(options: $options) {
                count
                result {
                    ...Asset
                }
            }
        }
        ${Fragments.asset}
    `;

    public static readonly getAssetByFileName = gql`
        query getAssetByFileName($fileName: String!) {
            getAssetByFileName(fileName: $fileName) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly checkAccountRecoveryTokenValidity = gql`
        query checkAccountRecoveryTokenValidity($email: String!, $token: String!) {
            checkAccountRecoveryTokenValidity(email: $email, token: $token)
        }
    `;

    public static readonly getHomeFolderContents = gql`
        query getHomeFolderContents($options: SearchInput) {
            getAssetDirectoriesByParentId(id: null) {
                ...AssetDirectory
            }
            search(options: $options) {
                count
                result {
                    ...Asset
                }
            }
        }
        ${Fragments.asset}
        ${Fragments.assetDirectory}
    `;

    public static readonly getFolderContents = gql`
        query getFolderContents($id: ID!, $options: SearchInput) {
            getAssetDirectoryById(id: $id) {
                ...AssetDirectory
            }
            search(options: $options) {
                count
                result {
                    ...Asset
                }
            }
        }
        ${Fragments.asset}
        ${Fragments.assetDirectory}
    `;

    public static readonly getActiveAssetExportJobs = gql`
        query getActiveAssetExportJobs {
            getActiveAssetExportJobs {
                ...AssetExportJob
            }
        }
        ${Fragments.assetExportJob}
    `;

    public static readonly getAssetExportJobById = gql`
        query getAssetExportJobById($id: ID!) {
            getAssetExportJobById(id: $id) {
                ...AssetExportJob
            }
        }
        ${Fragments.assetExportJob}
    `;

    public static readonly getBreadcrumDataForAssetDirectoryById = gql`
        query getBreadcrumDataForAssetDirectoryById($id: ID!) {
            getBreadcrumDataForAssetDirectoryById(id: $id) {
                id
                name
                deletable
            }
        }
    `;
}

export { Queries };
