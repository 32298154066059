import { ApiError, ApiErrorCode } from "@api/ApiError";
import { Mutations } from "@api/graphql/queries/Mutations";
import { Queries } from "@api/graphql/queries/Queries";
import { GraphQLClient } from "./graphql/GraphQLClient";
import * as GqlTypes from "./graphql/types";

class Api {
    public static async account() {
        const response = await GraphQLClient.query<GqlTypes.account>({
            query: Queries.account,
        });

        return response;
    }

    public static async login({
        email,
        password,
    }: {
        email: string;
        password: string;
    }): Promise<GqlTypes.login_login | null> {
        const response = await GraphQLClient.mutate<GqlTypes.login, GqlTypes.loginVariables>({
            mutation: Mutations.login,
            variables: {
                email,
                password,
            },
        });

        return response.login;
    }

    public static async logout(): Promise<GqlTypes.logout> {
        return GraphQLClient.mutate<GqlTypes.logout>({
            mutation: Mutations.logout,
        });
    }

    public static async refreshAccessToken(refreshToken: string): Promise<string> {
        const response = await GraphQLClient.mutate<GqlTypes.refreshAccessToken, GqlTypes.refreshAccessTokenVariables>({
            mutation: Mutations.refreshAccessToken,
            variables: {
                refreshToken,
            },
        });

        return response.refreshAccessToken;
    }

    public static async uploadAsset(title: string, file: File, directoryId: string | null): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.uploadAsset, GqlTypes.uploadAssetVariables>({
            mutation: Mutations.uploadAsset,
            variables: { title, file, directoryId },
        });
    }

    public static async updateAsset(id: string, title: string): Promise<GqlTypes.Asset> {
        const response = await GraphQLClient.mutate<GqlTypes.updateAsset, GqlTypes.updateAssetVariables>({
            mutation: Mutations.updateAsset,
            variables: {
                id,
                title,
            },
        });

        return response.updateAsset;
    }

    public static async deleteAsset(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAsset, GqlTypes.deleteAssetVariables>({
            mutation: Mutations.deleteAsset,
            variables: {
                id,
            },
        });
    }

    public static async deleteAssets(ids: string[]): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAssets, GqlTypes.deleteAssetsVariables>({
            mutation: Mutations.deleteAssets,
            variables: {
                ids,
            },
        });
    }

    public static async getAssetDirectoriesByParentId(
        parent: string | null = null
    ): Promise<GqlTypes.AssetDirectory[]> {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetDirectoriesByParentId,
            GqlTypes.getAssetDirectoriesByParentIdVariables
        >({
            query: Queries.getAssetDirectoriesByParentIdBase,
            variables: {
                id: parent,
            },
        });

        if (response.getAssetDirectoriesByParentId.some(dir => dir === null)) {
            return [];
        }
        return response.getAssetDirectoriesByParentId as GqlTypes.AssetDirectory[];
    }

    public static async getAssetDirectoriesByParentIdBase(
        parent: string | null = null
    ): Promise<GqlTypes.AssetDirectoryBase[]> {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetDirectoriesByParentIdBase,
            GqlTypes.getAssetDirectoriesByParentIdBaseVariables
        >({
            query: Queries.getAssetDirectoriesByParentIdBase,
            variables: {
                id: parent,
            },
        });

        if (response.getAssetDirectoriesByParentId.some(dir => dir === null)) {
            return [];
        }
        return response.getAssetDirectoriesByParentId as GqlTypes.AssetDirectoryBase[];
    }

    public static async getAssetDirectoryById(id: string): Promise<GqlTypes.AssetDirectory> {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetDirectoryById,
            GqlTypes.getAssetDirectoryByIdVariables
        >({
            query: Queries.getAssetDirectoryById,
            variables: {
                id,
            },
        });

        if (!response.getAssetDirectoryById) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }

        return response.getAssetDirectoryById;
    }

    public static async createAssetDirectory(
        variables: GqlTypes.createAssetDirectoryVariables
    ): Promise<GqlTypes.AssetDirectory | null> {
        const response = await GraphQLClient.mutate<
            GqlTypes.createAssetDirectory,
            GqlTypes.createAssetDirectoryVariables
        >({
            mutation: Mutations.createAssetDirectory,
            variables,
        });

        return response.createAssetDirectory;
    }

    public static async updateAssetDirectory(
        variables: GqlTypes.updateAssetDirectoryVariables
    ): Promise<GqlTypes.AssetDirectory | null> {
        const response = await GraphQLClient.mutate<
            GqlTypes.updateAssetDirectory,
            GqlTypes.updateAssetDirectoryVariables
        >({
            mutation: Mutations.updateAssetDirectory,
            variables,
        });

        return response.updateAssetDirectory;
    }

    public static async deleteAssetDirectory(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAssetDirectory, GqlTypes.deleteAssetDirectoryVariables>({
            mutation: Mutations.deleteAssetDirectory,
            variables: {
                id,
            },
        });
    }

    public static async getAssets(
        assetDirectory: string | null,
        listOptions?: GqlTypes.SearchListOptionsInput,
        recursive: boolean = false
    ): Promise<GqlTypes.search_search> {
        const response = await GraphQLClient.query<GqlTypes.search, GqlTypes.searchVariables>({
            query: Queries.search,
            variables: {
                options: {
                    assetDirectory,
                    listOptions,
                    recursive,
                },
            },
        });

        return response.search;
    }

    public static async getAssetByFileName(fileName: string): Promise<GqlTypes.Asset | null> {
        const response = await GraphQLClient.query<GqlTypes.getAssetByFileName, GqlTypes.getAssetByFileNameVariables>({
            query: Queries.getAssetByFileName,
            variables: {
                fileName,
            },
        });

        return response.getAssetByFileName;
    }

    public static async moveAssetsToAssetDirectory(assetIds: string[], directoryId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.moveAssetsToAssetDirectory, GqlTypes.moveAssetsToAssetDirectoryVariables>({
            mutation: Mutations.moveAssetsToAssetDirectory,
            variables: {
                assetIds,
                directoryId,
            },
        });
    }

    public static async initiateAccountRecovery(email: string) {
        await GraphQLClient.mutate<GqlTypes.initiateAccountRecovery, GqlTypes.initiateAccountRecoveryVariables>({
            mutation: Mutations.initiateAccountRecovery,
            variables: {
                email,
            },
        });
    }

    public static async finishAccountRecovery(email: string, token: string, password: string) {
        await GraphQLClient.mutate<GqlTypes.finishAccountRecovery, GqlTypes.finishAccountRecoveryVariables>({
            mutation: Mutations.finishAccountRecovery,
            variables: {
                email,
                token,
                password,
            },
        });
    }

    public static async checkAccountRecoveryTokenValidity(email: string, token: string) {
        const response = await GraphQLClient.query<
            GqlTypes.checkAccountRecoveryTokenValidity,
            GqlTypes.checkAccountRecoveryTokenValidityVariables
        >({
            query: Queries.checkAccountRecoveryTokenValidity,
            variables: {
                email,
                token,
            },
        });

        return response.checkAccountRecoveryTokenValidity;
    }

    public static async getFolderContents(folderId: string | null, listOptions: GqlTypes.SearchListOptionsInput) {
        const response = await (folderId
            ? GraphQLClient.query<GqlTypes.getFolderContents, GqlTypes.getFolderContentsVariables>({
                  query: Queries.getFolderContents,
                  variables: {
                      id: folderId,
                      options: {
                          assetDirectory: folderId,
                          listOptions,
                          recursive: false,
                      },
                  },
              })
            : GraphQLClient.query<GqlTypes.getHomeFolderContents, GqlTypes.getHomeFolderContentsVariables>({
                  query: Queries.getHomeFolderContents,
                  variables: {
                      options: {
                          assetDirectory: null,
                          listOptions,
                          recursive: false,
                      },
                  },
              }));

        return {
            assets: response.search.result,
            count: response.search.count,
            folders:
                "getAssetDirectoryById" in response
                    ? response.getAssetDirectoryById?.children
                    : response.getAssetDirectoriesByParentId,
        };
    }

    public static async getActiveAssetExportJobs() {
        const response = await GraphQLClient.query<GqlTypes.getActiveAssetExportJobs>({
            query: Queries.getActiveAssetExportJobs,
        });

        return response.getActiveAssetExportJobs;
    }

    public static async getAssetExportJobById(id: string) {
        const response = await GraphQLClient.query<
            GqlTypes.getAssetExportJobById,
            GqlTypes.getAssetExportJobByIdVariables
        >({
            query: Queries.getAssetExportJobById,
            variables: {
                id,
            },
        });

        return response.getAssetExportJobById;
    }

    public static async createAssetExport(assetIds: string[]) {
        const response = await GraphQLClient.mutate<GqlTypes.createAssetExport, GqlTypes.createAssetExportVariables>({
            mutation: Mutations.createAssetExport,
            variables: {
                assetIds,
            },
        });

        return response.createAssetExport;
    }

    public static async markAssetExportDownloadedById(id: string) {
        const response = await GraphQLClient.mutate<
            GqlTypes.markAssetExportDownloadedById,
            GqlTypes.markAssetExportDownloadedByIdVariables
        >({
            mutation: Mutations.markAssetExportDownloadedById,
            variables: {
                id,
            },
        });

        return response.markAssetExportDownloadedById;
    }

    public static async getBreadcrumDataForAssetDirectoryById(id: string) {
        const response = await GraphQLClient.query<
            GqlTypes.getBreadcrumDataForAssetDirectoryById,
            GqlTypes.getBreadcrumDataForAssetDirectoryByIdVariables
        >({
            query: Queries.getBreadcrumDataForAssetDirectoryById,
            variables: {
                id,
            },
        });

        return response.getBreadcrumDataForAssetDirectoryById;
    }

    public static async changePassword(currentPassword: string, newPassword: string) {
        await GraphQLClient.mutate<GqlTypes.changePassword, GqlTypes.changePasswordVariables>({
            mutation: Mutations.changePassword,
            variables: {
                currentPassword,
                newPassword,
            },
        });
    }

    public static async loginWithSignature(variables: {
        email?: string | null;
        projectId: string;
        apiKey: string;
        signature: string;
    }) {
        const response = await GraphQLClient.mutate<GqlTypes.loginWithSignature, GqlTypes.loginWithSignatureVariables>({
            mutation: Mutations.loginWithSignature,
            variables,
        });

        return response.loginWithSignature;
    }
}

export { Api };
