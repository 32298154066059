export enum IncomingEvent {
    InitOptions = "initOptions",
}

export enum OutgoingEvent {
    LoadComplete = "onLoad",
    ItemsSelected = "onItemsSelected",
    Close = "onCloseWithoutSelectionChange",
    AuthFailed = "onAuthFailed",
}

export const sendPMEvent = (event: OutgoingEvent, params?: object) => {
    window.parent.postMessage(JSON.stringify({ event: event, ...params }), "*");
};
